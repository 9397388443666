import React, { Dispatch, FC, SetStateAction } from 'react';

export const CastMember: FC<{
	name: string;
	text: string[];
	image: string;
	activeCastMember: string | null;
	setActiveCastMember: Dispatch<SetStateAction<string | null>>;
	setAboutText: Dispatch<SetStateAction<string[] | null>>;
}> = ({
	activeCastMember,
	name,
	text,
	image,
	setActiveCastMember,
	setAboutText,
}) => {
	const toggleActive = () => {
		if (activeCastMember !== name) {
			setAboutText(text);
			setActiveCastMember(name);
		} else {
			setAboutText(null);
			setActiveCastMember(null);
		}
	};
	return (
		<div
			className={
				activeCastMember === name ? 'castMember active' : 'castMember'
			}
			onClick={toggleActive}
		>
			<img src={image} alt={`${name}'s headshot`} className='castImage' />
			<small className='text-center mt-2'>{name}</small>
		</div>
	);
};
