import React, { FC } from 'react';

interface Props {
	index: number;
	info: {
		name: string;
		description: { text: string; style: string }[];
		image: string;
		extras: {
			performers: string[];
			choreography: string[];
			quote?: {
				quote: string;
				name: string;
			};
			conceptAndCreation?: string[];
			production?: string[];
			music?: string[];
			video?: string;
		};
	};
}

export const ShowCard: FC<Props> = (props) => {
	const { info, index } = props;
	const isEven = (index: number) => {
		if (index % 2 === 0) return true;
		return false;
	};
	const arraysMatch = (arr1: string[], arr2: string[]) => {
		if (arr1.length !== arr2.length) return false;
		for (let i = 0; arr1.length < i; i++) {
			if (arr1[i] !== arr2[i]) return false;
		}
		return true;
	};
	return (
		<div className='row my-2'>
			<div className='card'>
				<div className='card-body'>
					<div className='row align-items-center'>
						<div
							className={
								isEven(index)
									? 'col-12 col-md-6'
									: 'col-12 col-md-6'
							}
						>
							<img
								src={info.image}
								alt={info.name}
								className='img-fluid'
							/>
						</div>
						<div
							className={
								isEven(index)
									? 'col-12 col-md-6'
									: 'col-12 col-md-6'
							}
						>
							<h2 className='text-center'>{info.name}</h2>
							{info.description.map((paragraph, i) =>
								paragraph.style === 'bold' ? (
									<p key={info.name + '_text_' + i}>
										<b>{paragraph.text}</b>
									</p>
								) : (
									<p key={info.name + '_text_' + i}>
										{paragraph.text}
									</p>
								)
							)}
							{info.extras.quote && (
								<blockquote className='blockquote'>
									<p className='mb-0'>
										{info.extras.quote.quote}
									</p>
									<div className='blockquote-footer'>
										<cite title={info.extras.quote.name}>
											{info.extras.quote.name}
										</cite>
									</div>
								</blockquote>
							)}
							{info.extras.conceptAndCreation && (
								<p>
									<b>Concept and Creation: </b>
									{info.extras.conceptAndCreation.join(', ')}
								</p>
							)}
							{arraysMatch(
								info.extras.performers,
								info.extras.choreography
							) ? (
								<p>
									<b>Choreography and Performance: </b>
									{info.extras.performers.join(', ')}
								</p>
							) : (
								<>
									<p>
										<b>Choreography: </b>
										{info.extras.choreography.join(', ')}
									</p>
									<p>
										<b>Performers: </b>
										{info.extras.performers.join(', ')}
									</p>
								</>
							)}
							{info.extras.production && (
								<p>
									<b>Production: </b>
									{info.extras.production.join(', ')}
								</p>
							)}
							{info.extras.music && (
								<p>
									<b>Music: </b>
									{info.extras.music.join(', ')}
								</p>
							)}
							{info.extras.video && (
								<a
									href={info.extras.video}
									target='_blank'
									rel='noopener noreferrer'
								>
									Click here for the video!
								</a>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
