import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import { loadReCaptcha } from "react-recaptcha-v3";

import routes from "./routes";
import { Footer } from "./components/Footer/Footer";
import { LanguageContext } from "./components/LanguageContext/LanguageContext";
import { HelmetProvider } from "react-helmet-async";

const App = () => {
  useEffect(() => {
    loadReCaptcha("6LewbMgUAAAAAPsfBqjgPapPOYcRT0640lJ_Q0nk");
  }, []);

  const [lang, setLang] = useState<string>("en");

  return (
    <div className="App">
      <HelmetProvider>
        <LanguageContext.Provider value={lang}>
          <Navbar setLang={setLang} />
          <Switch>
            {routes.map((route) => (
              <Route
                exact
                key={route.path}
                path={route.path}
                component={route.component}
              />
            ))}
          </Switch>
          <Footer />
        </LanguageContext.Provider>
      </HelmetProvider>
    </div>
  );
};

export default App;
