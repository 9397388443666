import { truncate } from "../../../../utils/truncate";
import React, { useEffect, useState } from "react";

type PostType = {
  id: string;
  created_time: Date;
  message: string;
  story: string;
};

const getPosts: () => Promise<PostType[]> = async () => {
  const accessToken = process.env.REACT_APP_ACCESS_TOKEN;
  const res = await fetch(
    `https://graph.facebook.com/1915461382114948?fields=feed.limit(7)&access_token=${accessToken}`
  );

  const json: {
    feed: { data: PostType[]; paging: any[] };
    id: string;
  } = await res.json();

  return json.feed.data;
};

export const HomeNews = () => {
  const [posts, setPosts] = useState<PostType[]>();

  useEffect(() => {
    getPosts()
      .then((res) => setPosts(res.filter((post) => post.message).slice(0, 3)))
      .catch((e) => console.error(e));
  }, []);

  return (
    <div className="container">
      <div className="row my-5">
        <div className="col-12">
          <h3 className="text-center">Latest News</h3>
          <div className="row align-items-center">
            {posts &&
              posts.map(
                (post) =>
                  post.message && (
                    <div key={post.id} className="col-12 col-md-4 mb-3 mb-md-0">
                      <div className="card">
                        <div className="card-body">
                          <div className="container">
                            <div className="row">
                              <div className="col-12">
                                <a
                                  href="https://www.facebook.com/pg/KNOTkollektiv/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  KNOT Kollektiv
                                </a>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                <small className="text-muted">
                                  Posted{" "}
                                  {new Date(post.created_time).toDateString()}
                                </small>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                {truncate(post.message, 50)}
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-10">
                                <a
                                  href="https://www.facebook.com/pg/KNOTkollektiv/posts/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <button className="btn btn-sm btn-primary">
                                    See on Facebook
                                  </button>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
              )}
          </div>
        </div>
      </div>
    </div>
  );
};
