import React from 'react';

import bg from '../../images/Backgrounds/bluebg.jpg';

export const Footer = () => {
	return (
		<footer style={{ backgroundImage: `url(${bg})` }}>
			<div className='container' id='footer'>
				<div className='row'>
					<div className='col-12'>
						<div className='text-white' id='copytext'>
							&copy; Knot Kollektiv {new Date().getFullYear()} -
							All Rights Reserved
							<br />| <a href='/sitemap.xml'>Sitemap</a> |{' '}
							<a href='/privacypolicy.htm'>Privacy Policy</a> |{' '}
							<a href='/termsofservice.htm'>Terms of Service</a> |
							<br />
							<small>
								Photos by May van den Heuvel, Erik de Klerck &
								Jona Harnischmacher
							</small>
						</div>
					</div>
					<div className='col-12'>
						<small className='text-white'>
							Website by{' '}
							<a
								href='http://www.calcourtney.net'
								className='text-white'
								target='_blank'
								rel='noopener noreferrer'
							>
								Cal Courtney
							</a>
						</small>
					</div>
				</div>
			</div>
		</footer>
	);
};
