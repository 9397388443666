import React from 'react';
import { Helmet } from 'react-helmet-async';
import { HomeShows } from './components/HomeShows';
import Vision from './components/Vision';
import { HomeNews } from './components/HomeNews';

export const Home = () => {
	return (
		<>
			<Helmet>
				<meta
					name='description'
					content='The homepage of the KNOT Kollektiv.'
				/>
				<title>KNOT Kollektiv - Home</title>
			</Helmet>
			<HomeShows />
			<Vision />
			<HomeNews />
		</>
	);
};
