import React, { FC, useEffect, useState } from "react";
import bg from "../../../../images/Backgrounds/bluebg.jpg";
import { withLang } from "../../../LanguageContext/withLang";

import Spinner from "../../../Spinner";

const Vision: FC<{ lang: string }> = ({ lang }) => {
  const [visionText, setVisionText] = useState<string[]>();
  useEffect(() => {
    if (lang === "en")
      import(
        "../../../../data/EN/PageTexts/homepageData"
      ).then(({ homepageText }) => setVisionText(homepageText.vision));
    if (lang === "nl")
      import(
        "../../../../data/NL/PageTexts/homepageData"
      ).then(({ homepageText }) => setVisionText(homepageText.vision));
    if (lang === "de")
      import(
        "../../../../data/DE/PageTexts/homepageData"
      ).then(({ homepageText }) => setVisionText(homepageText.vision));
  }, [lang]);
  return (
    <div id="circustext" style={{ backgroundImage: `url(${bg})` }}>
      <div className="container-fluid">
        <div className="row pt-5" id="circustext">
          <div className="col-12">
            <div className="text-white p-5" id="circusparagraph">
              <h3 className="text-center">Vision</h3>
              {visionText ? (
                visionText.map((para) => <p key={para}>{para}</p>)
              ) : (
                <Spinner />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withLang(Vision);
