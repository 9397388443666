import React from 'react';
import { Helmet } from 'react-helmet-async';

import Cast from './components/Cast/Cast';
import { Supports } from './components/Supports/Supports';

export const About = () => {
	return (
		<div className='container'>
			<Helmet>
				<title>KNOT Kollektiv - About Us</title>
				<meta
					name='description'
					content='The "about us" section of the KNOT Kollektiv website.'
				/>
			</Helmet>
			<Cast />
			<div className='row' id='knotText'>
				<div className='col-12'>
					<div className='card my-3'>
						<div className='card-body'>
							<p className='card-text'>
								KNOT Kollektiv is an international and
								interdisciplinary group of artists based in the
								Netherlands working with dance, circus and in
								particular with audience participation as a part
								of art. Since 2017 KNOT Kollektiv have been
								developing and creating interdisciplinary work
								ranging from locations performances, to theatre
								works, often combining contemporary dance with
								elements of aerial circus and audience
								interaction. Having presented their first
								creation ‘It Didn’t Start With Us’ at the Café
								Theater Festival Utrecht, KNOT Kollektiv went on
								to be awarded the Jury Prize for Youth and
								Family Audiences. The duet has now toured
								nationally and internationally more than sixty
								times. Since then they have gone on to create
								‘Neither Did We’ and ‘Acoustic Resonance’, both
								pieces working with aerial rope and presented at
								circus events in the Netherlands. Now under
								creation is a trio ‘Internal Jogging’ which will
								premiere in March 2019 at Café Theater Festival
								Utrecht. KNOT Kollektiv will work extensively
								with the Café Theater Festival for the coming
								period 2019/2020 as the official CTF
								Onderzoekers receiving financial and artistic
								support from the festival in order to create a
								new work for the festival in 2020.
							</p>
							<Supports />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
