import React, { FC } from 'react';

interface Props {
	info: {
		name: string;
		description: { text: string; style: string }[];
		image: string;
		extras: {
			performers: string[];
			choreography: string[];
			quote?: {
				quote: string;
				name: string;
			};
			conceptAndCreation?: string[];
			production?: string[];
			music?: string[];
			video?: string;
		};
	};
}

export const MainShowCard: FC<Props> = (props) => {
	const arraysMatch = (arr1: string[], arr2: string[]) => {
		if (arr1.length !== arr2.length) return false;
		for (let i = 0; arr1.length < i; i++) {
			if (arr1[i] !== arr2[i]) return false;
		}
		return true;
	};
	return (
		<div className='row my-2'>
			<div
				className='card'
				style={{
					margin: '1rem 0',
					boxShadow: '0 0 20px 2px #1049d7',
				}}
			>
				<div className='card-body'>
					<div className='row align-items-center'>
						<div className='col-12 col-md-6'>
							<img
								src={props.info.image}
								alt={props.info.name}
								className='img-fluid'
							/>
						</div>
						<div className='col-12 col-md-6'>
							<h2 className='text-center'>{props.info.name}</h2>
							{props.info.description.map((paragraph, i) =>
								paragraph.style === 'bold' ? (
									<p key={props.info.name + '_text_' + i}>
										<b>{paragraph.text}</b>
									</p>
								) : (
									<p key={props.info.name + '_text_' + i}>
										{paragraph.text}
									</p>
								)
							)}
							{props.info.extras.quote && (
								<blockquote className='blockquote'>
									<p className='mb-0'>
										{props.info.extras.quote.quote}
									</p>
									<div className='blockquote-footer'>
										<cite
											title={props.info.extras.quote.name}
										>
											{props.info.extras.quote.name}
										</cite>
									</div>
								</blockquote>
							)}
							{props.info.extras.conceptAndCreation && (
								<p>
									<b>Concept and Creation: </b>
									{props.info.extras.conceptAndCreation.join(
										', '
									)}
								</p>
							)}
							{arraysMatch(
								props.info.extras.performers,
								props.info.extras.choreography
							) ? (
								<p>
									<b>Choreography and Performance: </b>
									{props.info.extras.performers.join(', ')}
								</p>
							) : (
								<>
									<p>
										<b>Choreography: </b>
										{props.info.extras.choreography.join(
											', '
										)}
									</p>
									<p>
										<b>Performers: </b>
										{props.info.extras.performers.join(
											', '
										)}
									</p>
								</>
							)}
							{props.info.extras.production && (
								<p>
									<b>Production: </b>
									{props.info.extras.production.join(', ')}
								</p>
							)}
							{props.info.extras.music && (
								<p>
									<b>Music: </b>
									{props.info.extras.music.join(', ')}
								</p>
							)}
							{props.info.extras.video && (
								<a
									href={props.info.extras.video}
									target='_blank'
									rel='noopener noreferrer'
								>
									Click here for the video!
								</a>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
