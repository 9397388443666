import React, { FC, useEffect, useRef, useState } from 'react';

import { CastMember } from './components/CastMember';
import { CastAboutBox } from './components/CastAboutBox';
import { withLang } from '../../../../LanguageContext/withLang';

import Spinner from './../../../../Spinner';

const Cast: FC<{ lang: string }> = ({ lang }) => {
	const [activeCastMember, setActiveCastMember] = useState<string | null>(
		null
	);
	const [aboutText, setAboutText] = useState<string[] | null>(null);
	const [castData, setCastData] = useState<
		{
			name: string;
			image: string;
			description: string[];
		}[]
	>();

	const [aboutHeight, setAboutHeight] = useState<number>(0);

	const aboutContent = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (lang === 'en')
			import(
				'../../../../../data/EN/castMemberData'
			).then(({ castData }) => setCastData(castData));
		if (lang === 'nl')
			import(
				'../../../../../data/NL/castMemberData'
			).then(({ castData }) => setCastData(castData));
		if (lang === 'de')
			import(
				'../../../../../data/DE/castMemberData'
			).then(({ castData }) => setCastData(castData));
	}, [lang]);

	useEffect(() => {
		aboutContent.current &&
			setAboutHeight(aboutContent.current.offsetHeight);
	}, [activeCastMember]);
	return (
		<>
			<div className='row my-3'>
				<div className='col-12'>
					<h3 className='text-center'>Meet the Cast</h3>
				</div>
				<div className='col-12 castMemberBox'>
					{castData ? (
						castData.map((castMember) => (
							<CastMember
								key={castMember.name}
								name={castMember.name}
								image={castMember.image}
								text={castMember.description}
								activeCastMember={activeCastMember}
								setActiveCastMember={setActiveCastMember}
								setAboutText={setAboutText}
							/>
						))
					) : (
						<Spinner />
					)}
				</div>
			</div>
			<CastAboutBox aboutHeight={aboutHeight}>
				<div className='aboutContent' ref={aboutContent}>
					<h3 className='card-title text-center'>
						{activeCastMember && activeCastMember}
					</h3>
					{aboutText &&
						aboutText.map((paragraph) => (
							<p key={paragraph}>{paragraph}</p>
						))}
				</div>
			</CastAboutBox>
		</>
	);
};

export default withLang(Cast);
