import { Home } from './components/Pages/Home/Home';
import Performances from './components/Pages/Performances/Performances';
import { About } from './components/Pages/About/About';
import { Contact } from './components/Pages/Contact/Contact';

const routes = [
	{ path: '/', component: Home },
	{ path: '/performances', component: Performances },
	{ path: '/about', component: About },
	{ path: '/contact', component: Contact },
];

export default routes;
