import React, { useState, useLayoutEffect, FC, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import { ShowCard } from './components/ShowCard';
import { MainShowCard } from './components/MainShowCard';
import { RouteComponentProps } from 'react-router-dom';
import { withLang } from '../../LanguageContext/withLang';
import Spinner from '../../Spinner';

interface Props extends RouteComponentProps {
	lang: string;
}

const Performances: FC<Props> = ({ lang, location }) => {
	const [mainComponent, setMainComponent] = useState<number | null>(null);

	const [performanceData, setPerformanceData] = useState<
		{
			name: string;
			description: { text: string; style: string }[];
			image: string;
			extras: {
				performers: string[];
				choreography: string[];
				quote?: {
					quote: string;
					name: string;
				};
				conceptAndCreation?: string[];
				production?: string[];
				music?: string[];
				video?: string;
			};
		}[]
	>();

	useEffect(() => {
		if (lang === 'en')
			import(
				'../../../data/EN/performanceData'
			).then(({ performancesData }) =>
				setPerformanceData(performancesData)
			);
		if (lang === 'nl')
			import(
				'../../../data/NL/performanceData'
			).then(({ performancesData }) =>
				setPerformanceData(performancesData)
			);
		if (lang === 'de')
			import(
				'../../../data/DE/performanceData'
			).then(({ performancesData }) =>
				setPerformanceData(performancesData)
			);
	}, [lang]);

	useLayoutEffect(() => {
		const query = location.search.split('=').slice(-1)[0];
		if (query === 'tsf') {
			setMainComponent(1);
		} else if (query === 'ij') {
			setMainComponent(2);
		} else if (query === 'd') {
			setMainComponent(3);
		} else {
			setMainComponent(null);
		}
		return () => {
			setMainComponent(null);
		};
	}, [location.search]);

	const createMainComponent = () => {
		if (mainComponent && performanceData)
			return <MainShowCard info={performanceData[mainComponent - 1]} />;
		return null;
	};
	return (
		<>
			<Helmet>
				<title>KNOT Kollektiv - Performances</title>
				<meta
					name='description'
					content='The performances section of the KNOT Kollektiv website.'
				/>
			</Helmet>
			<div className='container'>
				{mainComponent && createMainComponent()}
				{performanceData ? (
					performanceData.map((show, i) =>
						mainComponent ? (
							i !== mainComponent - 1 ? (
								<ShowCard
									key={show.name}
									info={show}
									index={i}
								/>
							) : null
						) : (
							<ShowCard key={show.name} info={show} index={i} />
						)
					)
				) : (
					<Spinner />
				)}
			</div>
		</>
	);
};

export default withLang(Performances);
