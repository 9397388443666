import React, { FC } from 'react';

export const CastAboutBox: FC<{ aboutHeight: number }> = ({
	aboutHeight,
	children,
}) => {
	return (
		<div
			className='row my-4'
			style={{ height: aboutHeight, transition: 'all 0.3s ease-in' }}
		>
			<div className='col-12'>
				<div className='card text-white castAboutBox'>
					<div className='card-body'>{children}</div>
				</div>
			</div>
		</div>
	);
};
