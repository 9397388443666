import voordekunst from '../images/Supports/Voordekunst Logo.png';
import performact from '../images/Supports/Performact Logo.png';
import panama from '../images/Supports/Panama Pictures Logo.jpg';
import podiumBloos from '../images/Supports/Podium Bloos logo.png';
import ctf from '../images/Supports/Cafe Theater Festival Logo.png';
import chasse from '../images/Supports/Chasse Theater Logo.jpg';
import gemeenteUtrecht from '../images/Supports/Gemeente Utrecht Logo.jpg';
import makersHuis from '../images/Supports/Makershuis Logo.png';
import untamed from '../images/Supports/Untamed Logo.jpg';
import fontys from '../images/Supports/Fontys Logo.jpg';
const supports = [
	{
		link: 'https://www.voordekunst.nl/',
		image: voordekunst,
	},
	{
		link: 'https://www.performact.net/',
		image: performact,
	},
	{
		link: 'https://www.chasse.nl/',
		image: chasse,
	},
	{
		link: 'https://www.utrecht.nl/',
		image: gemeenteUtrecht,
	},
	{
		link: 'https://podiumbloos.nl/',
		image: podiumBloos,
	},
	{
		link: 'https://panamapictures.nl/',
		image: panama,
	},
	{
		link: 'https://cafetheaterfestival.nl/',
		image: ctf,
	},
	{
		link: 'http://www.makershuistilburg.nl/',
		image: makersHuis,
	},
	{
		link: 'https://www.untamed-productions.com/',
		image: untamed,
	},
	{
		link: 'https://fontys.edu/',
		image: fontys,
	},
];

export default supports;
