import React, { FC, FormEvent, useState } from 'react';
import Axios from 'axios';

export const ContactForm: FC<{ verified: string | null }> = (props) => {
	const [name, setName] = useState<string>('');
	const [email, setEmail] = useState<string>('');
	const [message, setMessage] = useState<string>('');
	const [mailSent, setMailSent] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);

	const API_PATH = '/api/contact/contactus.php';

	const handleSubmit = (e: FormEvent) => {
		e.preventDefault();
		setError(null);
		if (props.verified) {
			Axios({
				method: 'post',
				url: API_PATH,
				headers: {
					'content-type': 'application/json',
					'Access-Control-Allow-Origin': '*',
				},
				data: { name, email, message },
			})
				.then((res) => {
					if (res.data.sent) {
						setMailSent(true);
						setName('');
						setEmail('');
						setMessage('');
					} else {
						setError(res.data.message);
					}
				})
				.catch((e) => setError(e.message));
		} else {
			setError(
				'Google believes you to be a bot. If this issue persists, please email the address provided.'
			);
		}
	};

	return (
		<form onSubmit={handleSubmit} encType='multipart/form-data'>
			{mailSent && (
				<div className='alert alert-success'>
					Thank you for contacting us. Your mail has been sent.
				</div>
			)}
			{error && <div className='alert alert-danger'>{error}</div>}
			<div className='form-group'>
				<label htmlFor='name'>Name:</label>
				<input
					name='name'
					type='text'
					className='form-control'
					value={name}
					onChange={(e) => {
						setName(e.target.value);
					}}
				/>
			</div>
			<div className='form-group'>
				<label htmlFor='email'>Email Address:</label>
				<input
					name='email'
					type='email'
					className='form-control'
					value={email}
					onChange={(e) => {
						setEmail(e.target.value);
					}}
				/>
			</div>
			<div className='form-group'>
				<label htmlFor='message'>Message:</label>
				<textarea
					name='message'
					id='formMessage'
					cols={30}
					rows={10}
					className='form-control'
					value={message}
					onChange={(e) => {
						setMessage(e.target.value);
					}}
				></textarea>
			</div>
			<input
				type='submit'
				value='Send'
				className='btn btn-primary d-block mx-auto'
			/>
		</form>
	);
};
