import React, { Dispatch, FC, SetStateAction } from 'react';
import mainLogo from '../../images/Logo/logoLargeWhite.svg';

import { NavLink, Link } from 'react-router-dom';
import { withLang } from '../LanguageContext/withLang';

const Navbar: FC<{
	lang: string;
	setLang: Dispatch<SetStateAction<string>>;
}> = ({ lang, setLang }) => {
	return (
		<>
			<div id='logo' className='bg-primary'>
				<div className='container'>
					<div className='row'>
						<div className='col-5 mx-auto'>
							<Link className='mx-auto' to='/'>
								<img
									src={mainLogo}
									alt='Knot Kollektiv Logo'
									className='img-fluid'
								/>
							</Link>
						</div>
					</div>
				</div>
			</div>
			<nav
				className='navbar navbar-expand-lg navbar-dark bg-secondary'
				id='nav'
			>
				<button
					className='navbar-toggler ml-auto'
					type='button'
					data-toggle='collapse'
					data-target='#navbarNavigation'
					aria-controls='navbarNavigation'
					aria-expanded='false'
					aria-label='Toggle navigation'
				>
					<span className='navbar-toggler-icon'></span>
				</button>
				<div className='collapse navbar-collapse' id='navbarNavigation'>
					<ul className='navbar-nav mx-auto'>
						<li className='nav-item'>
							<NavLink
								exact
								className='nav-link'
								to='/'
								activeClassName='active'
							>
								Home
							</NavLink>
						</li>
						<li className='nav-item'>
							<NavLink
								exact
								className='nav-link'
								to='/performances'
								activeClassName='active'
							>
								Performances
							</NavLink>
						</li>
						<li className='nav-item'>
							<NavLink
								exact
								className='nav-link'
								to='/about'
								activeClassName='active'
							>
								About Us
							</NavLink>
						</li>
						<li className='nav-item'>
							<NavLink
								exact
								className='nav-link'
								to='/contact'
								activeClassName='active'
							>
								Contact
							</NavLink>
						</li>
						<li className='nav-item dropdown'>
							<button
								className='btn btn-link nav-link dropdown-toggle ml-auto'
								id='navbarLanguageDropdown'
								data-toggle='dropdown'
								aria-haspopup='true'
								aria-expanded='false'
							>
								{lang.toUpperCase()}
							</button>
							<div
								className='dropdown-menu'
								aria-labelledby='navbarLanguageDropdown'
							>
								<button
									className={
										lang === 'en'
											? 'dropdown-item active'
											: 'dropdown-item'
									}
									onClick={() => setLang('en')}
								>
									EN
								</button>
								<button
									className={
										lang === 'nl'
											? 'dropdown-item active'
											: 'dropdown-item'
									}
									onClick={() => setLang('nl')}
								>
									NL
								</button>
								<button
									className={
										lang === 'de'
											? 'dropdown-item active'
											: 'dropdown-item'
									}
									onClick={() => setLang('de')}
								>
									DE
								</button>
							</div>
						</li>
					</ul>
				</div>
			</nav>
		</>
	);
};

export default withLang(Navbar);
