import React from 'react';
import supports from '../../../../../data/supportsData';

export const Supports = () => {
	return (
		<div className='row'>
			<div className='col-12'>
				<h5 className='text-center'>Supported by:</h5>
				<div
					style={{
						display: 'flex',
						flexWrap: 'wrap',
						justifyContent: 'center',
						alignItems: 'center',
					}}>
					{supports.map(support => (
						<a
							style={{
								flex: '1',
								maxHeight: '5rem',
								margin: '1rem',
							}}
							key={support.link}
							href={support.link}
							target='_blank'
							rel='noopener noreferrer'>
							<img
								src={support.image}
								alt={support.link}
								style={{
									maxHeight: '5rem',
									display: 'block',
									margin: '0 auto',
								}}
							/>
						</a>
					))}
				</div>
			</div>
		</div>
	);
};
