import React from 'react';
import { Link } from 'react-router-dom';

import tsf from '../../../../images/Shows/The-Sensitive-Future-Image.jpg';
import devour from '../../../../images/Shows/Devour-Home-Image.jpg';
import ij from '../../../../images/Shows/Internal-Jogging-Image.jpg';

export const HomeShows = () => {
	return (
		<div className='container'>
			<div className='row align-items-center'>
				<div className='col-4'>
					<div className='showinfo' id='show1'>
						<Link to='/performances?ref=tsf' className='showLink'>
							<h4>The Sensitive Future</h4>
						</Link>
					</div>
				</div>
				<div className='col-4'>
					<div className='showinfo' id='show2'>
						<Link to='/performances?ref=ij' className='showLink'>
							<h4>Internal Jogging</h4>
						</Link>
					</div>
				</div>
				<div className='col-4'>
					<div className='showinfo' id='show3'>
						<Link to='/performances?ref=d' className='showLink'>
							<h4>Devour</h4>
						</Link>
					</div>
				</div>
			</div>
			<div className='row'>
				<div className='col-4'>
					<Link to='/performances?ref=tsf' className='showLink'>
						<img
							src={tsf}
							alt='The sensitive future'
							className='img-fluid showImg'
						/>
					</Link>
				</div>
				<div className='col-4'>
					<Link to='/performances?ref=ij' className='showLink'>
						<img
							src={ij}
							alt='Internal Jogging'
							className='img-fluid showImg'
						/>
					</Link>
				</div>
				<div className='col-4'>
					<Link to='/performances?ref=d' className='showLink'>
						<img
							src={devour}
							alt='Devour'
							className='img-fluid showImg'
						/>
					</Link>
				</div>
			</div>
		</div>
	);
};
