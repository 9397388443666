import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { ContactForm } from './components/ContactForm';

import { ReCaptcha } from 'react-recaptcha-v3';

export const Contact = () => {
	const [verified, setVerified] = useState<string | null>(null);

	const verifyCallback = (reCaptchaToken: string) => {
		setVerified(reCaptchaToken);
	};
	return (
		<div className='container'>
			<ReCaptcha
				sitekey='6LewbMgUAAAAAPsfBqjgPapPOYcRT0640lJ_Q0nk'
				verifyCallback={verifyCallback}
				action='contact_form'
			/>
			<Helmet>
				<title>KNOT Kollektiv - Contact</title>
				<meta
					name='description'
					content="The online contact page from the KNOT Kollektiv's homepage."
				/>
			</Helmet>
			<div className='row my-4'>
				<div className='col-12 col-md-6 offset-md-1 order-2 order-md-1'>
					<div className='card'>
						<div className='card-body'>
							<h3 className='card-title text-center'>
								Contact Form
							</h3>
							<ContactForm verified={verified} />
						</div>
					</div>
				</div>
				<div className='col-12 col-md-4 order-1 order-md-2 m-auto'>
					<p className='text-center'>
						Email:
						<br />
						<a href='mailto:knotkollektiv@gmail.com'>
							knotkollektiv@gmail.com
						</a>
					</p>
				</div>
			</div>
		</div>
	);
};
